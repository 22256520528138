import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Firstsection from './Firstsection';
import Finpalbot from './Finpalbot';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';

function App() {
  return (
    <Router>
    <div className='app'>
      <Routes>
        <Route path='/' element={<Firstsection />} />
        <Route path='/finpalbot' element={<Finpalbot />} />
        <Route path='/login' element={<LoginForm />} />
        <Route path='/signup' element={<SignupForm />} />
      </Routes>
    </div>
    </Router>
  )
}

export default App
