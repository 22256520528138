import React from 'react'
import './Firstsection.css'
import { Link } from 'react-router-dom';
import Typewriter from './Typewriter.js';
import Header from './Header'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import FinancialNews from './FinancialNews.js';

function Firstsection() {
  return (
      <div>
    <div className='first_section'>
        <Header/>

    <div className='first_section_container'>
      <h1 className='first_section_title'>Welcome to FinPal</h1>
      <h3 className='first_section_subtitle'><Typewriter text='Tailored Financial Guidance for Drexel Students' delay={140} infinite /></h3>

      <div className='first_section_buttons'>
      <Link to='/finpalbot'><button className='firstsection_button'>Try FinPal Bot   <ArrowOutwardIcon className='fs_button_icon'/></button></Link>
      <button className='firstsection_button1'>About us</button>
      </div>

    </div>

    </div>

    <FinancialNews />


      </div>
  )
}

export default Firstsection
