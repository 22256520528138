import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyCmNXlXyQwH7r1YnjuiE1ISe0DuoqtrqEk",
  authDomain: "finpal-364e0.firebaseapp.com",
  projectId: "finpal-364e0",
  storageBucket: "finpal-364e0.appspot.com",
  messagingSenderId: "691632852122",
  appId: "1:691632852122:web:5897c198c7a603ed466323",
  measurementId: "G-N73DY8545W"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const GoogleAuth = new GoogleAuthProvider();