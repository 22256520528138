import React, { useState } from 'react';
import './loginform.css';
import { auth } from './firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

function SignupForm() {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);

    const signupHandle = async (event) => {
        event.preventDefault();
        try {
            if (password === confirmPassword) {
                await createUserWithEmailAndPassword(auth, email, password);
                navigate('/login');
            } else {
                setError('Passwords do not match');
            }
        } catch (error) {
            console.log(error.message);
            setError(error.message);
        }
    };

    return (
        <div className='login_body'>
            <form className='login_container' onSubmit={signupHandle}>
                <h2 className='login_title_form'>Sign Up</h2>

                <input 
                    className='form_input'
                    type='text'
                    placeholder='Name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />

                <input 
                    className='form_input' 
                    type='email'
                    placeholder='Email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />

                <input 
                    className='form_input'
                    type='password'
                    placeholder='Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />

                <input 
                    className='form_input'
                    type='password'
                    placeholder='Confirm Password'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                />

                {error && <h3>{error}</h3>}

                <div className='loginbuttons_section'>
                    <button 
                        className='loginpage_button' 
                        type="button"
                        onClick={() => navigate('/login')}
                    >
                        Login
                    </button>
                    <button className='loginpage_button' type='submit'>Sign Up</button>
                </div>
            </form>
        </div>
    );
}

export default SignupForm;
