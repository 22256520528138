import React, { useEffect, useState } from 'react';
import { auth, GoogleAuth } from './firebase';
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom'; 
import './loginform.css';

function LoginForm() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const navigate = useNavigate(); 

    const loginHandle = async (event) => {
        event.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            console.log('User logged in successfully');
            navigate('/finpalbot'); 
        } catch (error) {
            console.error('Error logging in:', error.message);
            setError(error.message);
        }
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setError('');
                navigate('/finpalbot'); // Redirect if already logged in
            } else {
                setError('');
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [navigate]); // Include navigate in dependency array

    return (
        <div className='login_body'>
            <form className='login_container' onSubmit={loginHandle}>
                <h2 className='login_title_form'>Login</h2>
                <input 
                    type='email' 
                    className='form_input' 
                    placeholder='Email...'
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                />
                <input 
                    type='password' 
                    className='form_input' 
                    placeholder='Password...'
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                />
               
               <h3>{error}</h3>

                <div className='loginbuttons_section'>
                    <button className='loginpage_button' type='submit'>Login</button>
                    <button 
                        className='loginpage_button' 
                        type="button"
                        onClick={() => navigate('/signup')}
                    >
                        Sign Up
                    </button>
                </div>
            </form>
        </div>
    );
}

export default LoginForm;
