import React from 'react'
import './Homebutton.css'

function Homebutton({title}) {
  return (
    <div className='homebutton'>
        <p className='homebutton_title'>{title}</p>
      
    </div>
  )
}

export default Homebutton
