import React from 'react'
import './Header.css'
import Homebutton from './Homebutton'
import { Link } from 'react-router-dom'
import logo from './images/drexel_logo.png'

function Header() {
  return (
    <div className='header_body'>
       
    <div  className='header_left'>
        <img src={logo} alt='logo' />
    </div>


    <div className='header_middle'>
        <Link to='/'><Homebutton title='Home'/></Link>
        <Link to='/finpalbot'><Homebutton title='FinPal' /></Link>
        <Homebutton title='Financial Advice' />
        <Homebutton title='Get in Touch' />

  

    </div>

        <div className='header_right'>
            <div className='login_button'>
              <h3 >Login</h3>
                </div>
              <div className='login_button'>
                <h3 >Sign Up</h3>
              </div>
        </div>



      
    </div>
  )
}

export default Header
