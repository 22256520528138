import React, { useState, useEffect, useRef } from 'react';
import './Finpalbot.css';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Check'; // Using a different icon for save
import DeleteIcon from '@mui/icons-material/Delete'; // Importing delete icon
import drexel_logo from './images/drexel_logo.png';
import { auth } from './firebase';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const API_KEY = "sk-proj-ikMJzvdwxkQKH6HYaJiiT3BlbkFJGD1EcJAC3LK7z2PmWmWO";

function Finpalbot() {
  const [sessions, setSessions] = useState([{ id: 0, name: 'Chat 1', messages: [] }]); // Initialize with one session
  const [currentSessionIndex, setCurrentSessionIndex] = useState(0); // Start with the first session
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state
  const [editMode, setEditMode] = useState(null); // Track which chat is being edited
  const navigate = useNavigate(); // Initialize useNavigate

  const messagesEndRef = useRef(null);
  const chatHistoryEndRef = useRef(null); // Ref for the bottom of the chat history

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToChatHistoryEnd = () => {
    chatHistoryEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [sessions, currentSessionIndex]);

  useEffect(() => {
    scrollToChatHistoryEnd();
  }, [sessions]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, you can redirect or update state
      } else {
        // User is signed out, redirect to login
        navigate('/login');
      }
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [navigate]);

  const sendMessage = async () => {
    if (input.trim() === '') return;

    const userMessage = { text: input, sender: 'user' };
    const updatedSessions = [...sessions];
    updatedSessions[currentSessionIndex].messages.push(userMessage);
    setSessions(updatedSessions);

    setInput(''); // Clear input field
    setLoading(true); // Set loading to true

    const system_role = "You are a confident financial advisor. Answer this question in a max of 150tokens. This bot is specifically tailored to give financial advice for Drexel students."

    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${API_KEY}` // Ensure the API key is prefixed with 'Bearer '
        },
        body: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: [
            { role: "system", content: system_role  },
            { role: "user", content: input }
          ],
          max_tokens: 150,
          temperature: 1
        })
      });

      const data = await response.json();

      if (!data.choices || !data.choices[0] || !data.choices[0].message) {
        throw new Error("Invalid response structure");
      }

      const botMessage = { text: data.choices[0].message.content.trim(), sender: 'bot' };
      updatedSessions[currentSessionIndex].messages.push(botMessage);
      setSessions(updatedSessions);
    } catch (error) {
      console.error('Error:', error);
      const errorMessage = { text: `Sorry, there was an error processing your request: ${error.message}`, sender: 'bot' };
      updatedSessions[currentSessionIndex].messages.push(errorMessage);
      setSessions(updatedSessions);
    } finally {
      setLoading(false); // Set loading to false after API call completes
    }
  };

  const handleNewChat = () => {
    const newSession = { id: sessions.length, name: `Chat ${sessions.length + 1}`, messages: [] };
    setSessions([...sessions, newSession]);
    setCurrentSessionIndex(sessions.length); // Set the new session as the current session
  };

  const handleSelectChat = (index) => {
    if (editMode === null) {
      setCurrentSessionIndex(index);
    }
  };

  const toggleEditMode = (index) => {
    setEditMode(editMode === index ? null : index);
  };

  const handleRenameChat = (index, newName) => {
    const updatedSessions = [...sessions];
    updatedSessions[index].name = newName;
    setSessions(updatedSessions);
  };

  const handleDeleteChat = (index) => {
    const updatedSessions = sessions.filter((_, i) => i !== index);
    setSessions(updatedSessions);
    if (currentSessionIndex >= index && currentSessionIndex > 0) {
      setCurrentSessionIndex(currentSessionIndex - 1);
    } else if (currentSessionIndex === index) {
      setCurrentSessionIndex(0);
    }
  };

  const logoutHandle = async () => {
    try {
      await signOut(auth);
      navigate('/login'); // Redirect to login page after logout
    } catch (error) {
      console.error('Error:', error);
    }
  }

  return (
    <div className='finpal_body'>
      <div className='finpal_left'>
        <div className='finpal_left_header'>
          <img src={drexel_logo} alt='drexel_logo' />
        </div>
        <div className='new_chatbutton'>
          <button className='new_chat_button' onClick={handleNewChat}>New Chat</button>
        </div>
        <div className='past_chats'>
          {sessions.map((session, index) => (
            <div key={session.id} className='chat_history' onClick={() => handleSelectChat(index)}>
              {editMode === index ? (
                <input
                  type="text"
                  value={session.name}
                  onChange={(e) => handleRenameChat(index, e.target.value)}
                  className="chat_name_input"
                />
              ) : (
                <div className="chat_name">
                  {session.name}
                </div>
              )}
              <div className="chat_actions">
                <button className='edit_button' onClick={(e) => {
                  e.stopPropagation(); // Prevent the click event from propagating to the chat_history div
                  toggleEditMode(index);
                }}>
                  {editMode === index ? <SaveIcon className='chat_bar_icon' /> : <EditIcon className='chat_bar_icon' />}
                </button>
                <button className='edit_button' onClick={(e) => {
                  e.stopPropagation(); // Prevent the click event from propagating to the chat_history div
                  handleDeleteChat(index);
                }}>
                  <DeleteIcon className='chat_bar_icon' />
                </button>
              </div>
            </div>
          ))}
          <div ref={chatHistoryEndRef} /> {/* Reference to the bottom of the chat history */}
        </div>
        <div className='finpal_left_footer'>
          <button className='new_chat_button' onClick={logoutHandle}>Log Out</button>
        </div>
      </div>

      <div className='finpal_right'>
        <div className='finpal_right_header'>
          {/* nothing needed */}
        </div>

        <div className='chat_background'>
          <div className='bot_messages'>
            {sessions[currentSessionIndex]?.messages.map((msg, index) => (
              <div key={index} className={msg.sender === 'bot' ? 'bot_message_container' : 'user_message_container'}>
                <p className='message'>{msg.text}</p>
              </div>
            ))}
            {loading && (
              <div className="bot_message_container">
                <p className="message">The bot is thinking...</p>
                <div className="spinner"></div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>

          <div className='user_input_section'>
            <div className='input_bar'>
              <input
                type='text'
                placeholder='Type a message'
                className='user_input'
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') sendMessage();
                }}
                disabled={currentSessionIndex === null}
              />
              <button id='user_input_submit' onClick={sendMessage} disabled={currentSessionIndex === null}>
                <ArrowUpwardIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Finpalbot;
