import React, { useState, useEffect } from 'react';
import './financialnews.css';

function FinancialNews() {
    const [articles, setArticles] = useState([]);
    const [error, setError] = useState(null);

    const url = 'https://seeking-alpha.p.rapidapi.com/articles/v2/list-trending?size=12';
    const options = {
        method: 'GET',
        headers: {
            'X-RapidAPI-Key': 'beb99377bcmshe357a47be4f340bp1a1347jsn388d51cd2a51',
            'X-RapidAPI-Host': 'seeking-alpha.p.rapidapi.com'
        }
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(url, options);
                const result = await response.json(); // Assuming the API returns JSON
                setArticles(result.data);
            } catch (error) {
                setError(`Error: ${error.message}`);
            }
        }

        fetchData();
    }, []); // Empty dependency array means this effect runs once on mount

    return (
        <div className='financial_news'>
            <h2>Financial News</h2>
            {error && <p>{error}</p>}
            <div id='articles'>
                {articles.map(article => (
                    <div key={article.id} className='article'>
                        <h2>{article.attributes.title}</h2>
                        <img src={article.links.uriImage} alt={article.attributes.title} />
                        <a href={`https://seekingalpha.com${article.links.self}`} target='_blank' rel='noopener noreferrer'>
                            Read more
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FinancialNews;
